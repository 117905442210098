/* Archive.css */

/* Style for the sponsors carousel container */
.sponsors-carousel {
  overflow: hidden;
  width: 100%;
  position: relative;
  margin-bottom: 20px; /* Space between rows */
}

/* Style for the track of sponsors logos */
.sponsors-track {
  display: flex;
  animation: scroll 30s linear infinite;
  width: fit-content; /* Ensures the track can be wide enough to fit all logos */
}

/* Style for individual sponsor logos */
.sponsor-logo {
  width: 150px; /* Adjust width as needed */
  height: 80px; /* Adjust height as needed */
  background-color: #e0e0e0; /* Grey placeholder for sponsor logos */
  border-radius: 4px; /* Rounded corners */
  margin: 0 10px; /* Space between logos */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for logos */
}

/* Animation for scrolling sponsor logos */
@keyframes scroll {
  0% {
      transform: translateX(0);
  }
  100% {
      transform: translateX(-50%);
  }
}
.responsive-text {
  font-size: clamp(1rem, 2vw, 1.5rem);
}